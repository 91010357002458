/* reference: https://theme-ui.com/theming */
/* eslint-disable import/prefer-default-export */
export const editorTheme = {
  plain: {
    color: 'var(--ds-color-london-5)',
    backgroundColor: '#FAFAFA',
    fontFamily: 'SFMono-Regular, Consolas, Liberation Mono ,Menlo, monospace',
    fontSize: 15,
    lineHeight: 1.4,
  },
  styles: [
    {
      types: ['prolog', 'comment', 'doctype', 'cdata'],
      style: {
        color: 'var(--ds-color-london-35)',
      },
    },
    {
      types: [
        'property',
        'tag',
        'boolean',
        'number',
        'constant',
        'symbol',
        'atrule',
        'attr-value',
        'keyword',
      ],
      style: { color: 'var(--ds-color-chicago-30)' },
    },
    {
      types: ['attr-name', 'string', 'char', 'builtin', 'insterted'],
      style: {
        color: 'var(--ds-color-tokyo-55)',
      },
    },
    {
      types: [
        'operator',
        'entity',
        'url',
        'string',
        'variable',
        'language-css',
      ],
      style: {
        color: 'var(--ds-color-keiv)',
      },
    },
    {
      types: ['deleted'],
      style: {
        color: 'var(--ds-color-tokyo-45)',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['important', 'bold'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: ['regex', 'important'],
      style: {
        color: 'var(--ds-color-chicago-20)',
      },
    },
    {
      types: ['punctuation', 'symbol'],
      style: {
        opacity: '0.7',
      },
    },
  ],
};
